import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Divider, Image, message, Modal,
} from 'antd';

import { loadEmployers } from '../../../services/settings/employers';

import styles from './news-window.module.scss';

const NewsWindow = () => {
  const [visibilityStatus, setVisibilityStatus] = useState(false);

  const company = useSelector((state) => state.user.company);
  const user = useSelector((state) => state.user.user);
  const employers = useSelector((state) => state.employers.employers);

  const dispatch = useDispatch();

  useEffect(() => {
    if (company.type && employers.data) {
      setVisibilityStatus(
        company.type === 2
          && employers.data[0].role === 'Owner'
          && !sessionStorage.getItem('isNewsWindowInvisible'),
      );
    }
  }, [company, employers]);

  useEffect(() => {
    dispatch(loadEmployers({ search: user.name })).catch(() => {
      message.error('Не удалось загрузить перечень сотрудников');
    });
  }, [user]);

  const handleNewsWindowClose = () => {
    sessionStorage.setItem('isNewsWindowInvisible', true);
    setVisibilityStatus(false);
  };

  return (
    <Modal
      title="Новые возможности для руководителя"
      open={visibilityStatus}
      destroyOnClose
      footer={null}
      onCancel={handleNewsWindowClose}
      className={styles['news-window']}
    >
      <h3 className={styles['news-window__title']}>
        Вы можете настроить свои лимиты внутри личного кабинета Инвойс
      </h3>
      <p>Лимиты могут быть:</p>
      <ul>
        <li>на организацию;</li>
        <li>на сотрудника;</li>
        <li>на точку продаж;</li>
        <li>на терминал.</li>
      </ul>
      <p>Для каждого типа лимитов доступен Тип лимита:</p>
      <ul>
        <li>по сумме операций;</li>
        <li>по сумме операций на получателя;</li>
        <li>по количеству операций;</li>
        <li>по количеству операций на получателя.</li>
      </ul>
      <p>Период лимита:</p>
      <ul>
        <li>на операцию;</li>
        <li>в минуту;</li>
        <li>в час;</li>
        <li>в день;</li>
        <li>в месяц.</li>
      </ul>
      <Divider />
      <h3 className={styles['news-window__title']}>
        Визуализация нововведений
      </h3>
      <div className={styles['news-window__images-wrapper']}>
        <div className={styles['news-window__image-container']}>
          <Image
            src="/img/news-window-1.jpg"
            alt="окно создания лимита"
            className={styles['news-window__image']}
          />
        </div>
        <div className={styles['news-window__image-container']}>
          <Image
            src="/img/news-window-2.jpg"
            alt="таблица лимитов"
            className={styles['news-window__image']}
          />
        </div>
      </div>
      <div className={styles['news-window__controls']}>
        <Button type="primary" onClick={handleNewsWindowClose}>
          Понятно
        </Button>
      </div>
    </Modal>
  );
};
export default NewsWindow;
