const getCurrentRoleValues = (newPoints, form, key, roleValue) => {
  if (Array.isArray(key)) {
    newPoints[key[0]].terminals[key[1]].role = roleValue;
    form.setFieldsValue({ [`terminal_${key[0]}_${key[1]}`]: roleValue });
  } else if (key === 0) {
    newPoints.forEach((item, itemIndex) => {
      if (item.role < roleValue) {
        item.role = roleValue;
        form.setFieldsValue({ [`point_${itemIndex}`]: roleValue });
      }
      if (item.terminals && item.terminals.length) {
        item.terminals.forEach((elem, elemIndex) => {
          if (elem.role < roleValue) {
            elem.role = roleValue;
            form.setFieldsValue({
              [`terminal_${itemIndex}_${elemIndex}`]: roleValue,
            });
          }
        });
      }
    });
    newPoints[key].role = roleValue;
  } else {
    newPoints[key].terminals.forEach((elem, elemIndex) => {
      if (elem.role < roleValue) {
        elem.role = roleValue;
        form.setFieldsValue({ [`terminal_${key}_${elemIndex}`]: roleValue });
      }
    });
    newPoints[key].role = roleValue;
  }

  return newPoints;
};

export default getCurrentRoleValues;
