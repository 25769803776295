const employersRoles = {
  Guest: 'Нет прав',
  Analyst: 'Аналитик',
  Seller: 'Продавец',
  Administrator: 'Администратор',
};

const ownerRole = {
  Owner: 'Владелец',
};

const accessTypes = [
  {
    title: 'Администратор',
    description: 'полные права к объекту, может создавать новые заказы, отчеты и возвраты',
  },
  {
    title: 'Продавец',
    description: 'может создавать новые заказы',
  },
  {
    title: 'Аналитик',
    description: 'может создавать отчеты',
  },
  {
    title: 'Нет прав',
    description: 'нет доступа к этому объекту',
  },
];

export { employersRoles, ownerRole, accessTypes };
