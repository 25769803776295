import Head from 'next/head';
import PropTypes from 'prop-types';

import Authorization from '../../hocs/authorization';
import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';
// import NewsAlert from '../../components/shared/news-alert/news-alert';
import NewsWindow from '../../components/shared/news-window/news-window';

import styles from './main-layout.module.scss';

const MainLayout = ({ children, title: pageTitle }) => (
  <>
    <Head>
      <title>{pageTitle ? `Invoice | ${pageTitle}` : 'Invoice'}</title>
    </Head>

    {pageTitle === 'Личный кабинет' ? (
      <>{children}</>
    ) : (
      <>
        {/* <NewsAlert /> */}
        <div className={styles['main-grid']}>
          <div className="d-lg-block">
            <Sidebar />
          </div>
          <div className={styles.content}>
            <Header pageTitle={pageTitle} />
            <div className="content">
              <main>{children}</main>
            </div>
          </div>
        </div>
        <NewsWindow />
      </>
    )}
  </>
);

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default Authorization(MainLayout);
