import cloneDeep from 'lodash/cloneDeep';
import { employersRoles } from '../../constants/settings/employers';
// import { phoneNumberFormatter } from '../../formatters';

const employersRolesKeys = Object.keys(employersRoles);

const getEmployerItem = (availableTree, employerDetails = {}) => {
  const currentItem = {
    id: employerDetails.id || null,
    name: employerDetails.name || '',
    // phone: employerDetails.phone ? phoneNumberFormatter(employerDetails.phone) : '',
    phone: employerDetails.phone || '',
    comment: employerDetails.comment || '',
  };

  const userRole = employerDetails.role
    ? employersRolesKeys.indexOf(employerDetails.role) + 1
    : 1;

  const userRolesData = [
    {
      key: 0,
      name: 'Все',
      role: userRole,
    },
  ];

  const initialRoles = {
    point_0: userRole,
  };

  availableTree.slice().forEach((item, itemIndex) => {
    const newItem = cloneDeep(item);

    const currentPointOfSaleSet = employerDetails.pointOfSalePermissions
      ? employerDetails.pointOfSalePermissions.filter(
        (pointPermission) => pointPermission.pointOfSale.id === newItem.id,
      )
      : null;

    const currentPointOfSale = currentPointOfSaleSet && currentPointOfSaleSet.length
      ? currentPointOfSaleSet[0]
      : null;

    const currentPointOfSaleRole = currentPointOfSale && currentPointOfSale.role
      ? employersRolesKeys.indexOf(currentPointOfSale.role) + 1
      : userRole;

    newItem.key = itemIndex + 1;
    newItem.role = currentPointOfSaleRole;
    initialRoles[`point_${itemIndex + 1}`] = currentPointOfSaleRole;

    newItem.terminals.forEach((elem, elemIndex) => {
      const currentTerminalSet = currentPointOfSale && currentPointOfSale.terminalPermissions ? currentPointOfSale.terminalPermissions.filter(
        (terminalPermission) => terminalPermission.terminal.id === elem.id,
      ) : null;

      const currentTerminal = currentTerminalSet && currentTerminalSet.length ? currentTerminalSet[0] : null;

      const currentTerminalRole = currentTerminal && currentTerminal.role
        ? employersRolesKeys.indexOf(currentTerminal.role) + 1
        : currentPointOfSaleRole;

      elem.key = [itemIndex + 1, elemIndex];
      elem.role = currentTerminalRole;
      initialRoles[`terminal_${itemIndex + 1}_${elemIndex}`] = currentTerminalRole;
    });

    userRolesData.push(newItem);
  });

  return {
    ...currentItem,
    pointsOfSale: userRolesData,
    ...initialRoles,
  };
};

export default getEmployerItem;
