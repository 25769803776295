import cloneDeep from 'lodash/cloneDeep';

const getCurrentRolesTableData = (pointsOfSale, searchInfo) => {
  const currentRolesTableData = [];
  const substring = searchInfo.toLowerCase();

  const basisItems = pointsOfSale.map((item) => cloneDeep(item));
  basisItems.shift();

  basisItems.forEach((item) => {
    if (item.name.toLowerCase().includes(substring)) {
      currentRolesTableData.push(item);
    } else {
      const currentTerminals = item.terminals.filter((terminal) => terminal.name.toLowerCase().includes(substring));

      if (currentTerminals.length) {
        item.terminals = currentTerminals;
        currentRolesTableData.push(item);
      }
    }
  });

  return currentRolesTableData;
};

export default getCurrentRolesTableData;
