import { employersRoles } from '../../constants/settings/employers';
import { adaptPhoneNumber } from '../shared';

const employersRolesKeys = Object.keys(employersRoles);

const adaptRequestBody = (values) => {
  const requestBody = {
    name: values.name,
    phone: values.id ? values.phone : adaptPhoneNumber(values.phone),
    comment: values.comment,
    role: employersRolesKeys[values.pointsOfSale[0].role - 1],
    pointsOfSale: [],
  };

  if (values.id) requestBody.id = values.id;

  const newValues = values.pointsOfSale.slice();
  newValues.shift();

  newValues.forEach((item) => {
    const currentPoint = {
      pointOfSale: item.id,
      role: employersRolesKeys[item.role - 1],
      terminals: [],
    };

    if (item.terminals && item.terminals.length) {
      item.terminals.forEach((elem) => {
        const currentTerminal = {
          terminal: elem.id,
          role: employersRolesKeys[elem.role - 1],
        };

        currentPoint.terminals.push(currentTerminal);
      });
    }

    requestBody.pointsOfSale.push(currentPoint);
  });

  Object.keys(requestBody).forEach((key) => {
    if (requestBody[key] === '') {
      requestBody[key] = null;
    }
  });

  return requestBody;
};

export default adaptRequestBody;
