import humps from 'humps';
import { ActionCreator } from '../../store/action/settings/employers';
import { adaptRequestBody } from '../../utils/helpers/employers';

export const loadEmployers = (body) => (dispatch, _getState, api) => api.post('/roles/users', humps.decamelizeKeys(body)).then(({ data }) => {
  dispatch(ActionCreator.setEmployers(humps.camelizeKeys(data)));
});

export const loadEmployerDetails = (body) => (dispatch, _getState, api) => api.post('/roles/users/detail', humps.decamelizeKeys(body));
// export const loadEmployerDetails = (body) => (dispatch, _getState, api) => api.post('/roles/users/detail', humps.decamelizeKeys(body)).then(({ data }) => {
//   dispatch(ActionCreator.setEmployerDetails(humps.camelizeKeys(data)));
// });

export const loadRoles = () => (dispatch, _getState, api) => api.post('/roles/list').then(({ data }) => {
  dispatch(ActionCreator.setRoles(humps.camelizeKeys(data)));
});

export const loadAvailableTree = () => (dispatch, _getState, api) => api.post('/roles/available_tree').then(({ data }) => {
  dispatch(ActionCreator.setAvailableTree(humps.camelizeKeys(data)));
});

export const createEmployer = (body) => (dispatch, _getState, api) => api.post('/roles/users/add', humps.decamelizeKeys(adaptRequestBody(body)));

export const updateEmployer = (body) => (dispatch, _getState, api) => api.post('/roles/users/update', humps.decamelizeKeys(adaptRequestBody(body)));

export const deleteEmployer = (body) => (dispatch, _getState, api) => api.post('/roles/users/delete', humps.decamelizeKeys(body));
